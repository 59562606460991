<template>
  <div class="wrapper">
    <div class="nav">
      <div class="content">
        <div class="col-one">
          <img src="../../../assets/IWC HEADER-HD.png" alt="" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="content">
        <div class="layout">
          <div class="web">
            <h1>GET THE PROTECTION THEY DESERVE!</h1>
            <p>
              Ensure your family's future is safeguarded against unforeseen
              events with our comprehensive Accident Insurance coverage.
              Protecting against Accidental Death, Disablement, and
              Dismemberment, our plans offer peace of mind during life's most
              challenging moments. With customizable options tailored to your
              specific needs, you can provide your loved ones with the security
              they deserve, allowing them to face the future with confidence.
            </p>
          </div>
          <div class="mobile">
            <p>
              Health emergencies and accidents are unpredictable. IWC is
              committed to protect you and your loved ones with the kind of
              service you can trust. Your health is more than a promise!
            </p>
            <router-link to="/deped/category" class="get-started">
              Get started
              <rightSVG
            /></router-link>
            <div class="portal">
              Have a pending application for IWC Healthcare Program?

              <span>click <b @click="routeHandler"> here </b></span>
            </div>
          </div>
        </div>
        <div class="avatar">
          <img src="../../../assets/images/banner111.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import rightSVG from "@/components/svgs/rightSVG.vue";
import { inject } from "vue";
export default {
  components: {
    rightSVG,
  },
  setup() {
    const router = inject("$router");
    const routeHandler = () => {
      router.push({ name: "retrieve" });
    };
    return {
      routeHandler,
    };
  },
};
</script>
<style scoped>
.wrapper {
  color: rgb(24, 24, 24);
  height: 100vh;
}
.nav {
  background-color: #fff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.nav .content {
  max-width: 1320px;
  margin: auto;
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav .content .col-one img {
  height: 100px;
  width: auto;
}
.nav .content .col-two span {
  background-color: #f29900;
  color: #fff;
  padding: 15px 30px;
  border-radius: 10px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.container {
  max-width: 1050px;
  margin: auto;
  padding: 3% 5% 0% 5%;
}
.container .content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
}
.container .content .layout .web {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container .content .layout .web h1 {
  font-size: 56px;
  color: #262626;
  text-align: center;
}
.container .content .layout .web p {
  margin: 25px 0px;
  font-size: 18px;
  color: #262626;
  text-align: center;
  line-height: 28px;
}
.container .content .layout .mobile {
  display: none;
}
.container .avatar {
  margin: 10px 0px;
}
.container .avatar img {
  height: 680px;
  width: auto;
}
@media only screen and (max-width: 430px) {
  .wrapper {
    background: url("../../../assets/images/mb.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 113% 100%;
    color: white;
    height: 100vh;
  }
  .nav {
    display: none;
  }
  .container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 45% 5% 0% 5%;
  }
  .container .content .layout .web {
    display: none;
  }
  .container .content .layout .mobile {
    display: block;
  }
  .container .content .layout .mobile p {
    line-height: 26px;
    font-size: 18px;
    font-family: Montserrat;
    color: #e4dede;
    font-weight: 400;
    display: block;
  }
  .container .content .avatar {
    display: none;
  }
  .container .content .layout .mobile .get-started {
    margin: 35px 0px 8px 0px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: transparent;
    border-radius: 30px;
    background-color: #fa7319;
    font-family: Montserrat;
    color: #fff;
    font-size: 21px;
    max-width: 180px;
    font-weight: 600;
    text-decoration: none;
  }
  .container .content .layout .mobile .get-started:hover {
    background-color: #d65e0d;
  }
  .container .content .layout .mobile .portal {
    margin: 15px 0px;
  }
  .container .content .layout .mobile .portal b {
    color: #ff5100;
  }
  router-link {
    text-decoration: none;
    color: #fff;
  }
}
</style>
