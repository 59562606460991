<template>
  <RetrieveApplication />
</template>
<script>
import RetrieveApplication from "@/components/partials/general/RetrieveMembership.vue";
export default {
  components: {
    RetrieveApplication,
  },
  setup() {},
};
</script>
