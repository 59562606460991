<template>
  <GettingStarted />
</template>
<script>
import GettingStarted from "@/components/partials/general/GettingStarted.vue";
export default {
  components: {
    GettingStarted,
  },
  setup() {},
};
</script>
