<template>
  <QRScanner />
</template>
<script>
import QRScanner from "@/components/partials/general/QRScanner.vue";
export default {
  components: {
    QRScanner,
  },
  setup() {},
};
</script>
