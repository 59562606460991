import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  function (config) {
    console.log(process.env.VUE_APP_SECRET_KEY);
    config.headers["x-api-key"] = process.env.VUE_APP_SECRET_KEY;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
