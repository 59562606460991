import { createRouter, createWebHashHistory } from "vue-router";
import LandingPage from "../pages/LandingIndex.vue";
import QRScannerPage from "../pages/QRScannerIndex.vue";
import ApplicationPage from "../pages/ApplicationIndex.vue";
import CompletedPage from "../pages/CompletedIndex.vue";
import HoldPaymentPage from "../pages/HoldPaymentIndex.vue";
import RetrievePage from "../pages/RetrieveIndex.vue";
import CategoryOptionPage from "../pages/OptionCategoryIndex.vue";
const routes = [
  {
    path: "/",
    name: "index",
    component: LandingPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/deped/alert/transaction/temp/meta/api/raw/:id",
    name: "message",
    component: CompletedPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/deped/alert/transaction/temp/meta/api/raw/hold/:id",
    name: "holdMessage",
    component: HoldPaymentPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/qr-scanner",
    name: "qr",
    component: QRScannerPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/qr-scanner/application-form/deped/:id",
    name: "application",
    component: ApplicationPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/deped/iwc/account/application/retrieve/",
    name: "retrieve",
    component: RetrievePage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
  {
    path: "/deped/category",
    name: "categoryOption",
    component: CategoryOptionPage,
    meta: {
      layout: "General",
      requiresAuth: false,
      type: "client",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  document.title = `Welcome to IWC DEPED Application`;
});
export default router;
