<template>
  <GreetingHoldMessage />
</template>
<script>
import GreetingHoldMessage from "@/components/partials/general/application/GreetingHoldMessage.vue";
export default {
  components: {
    GreetingHoldMessage,
  },
  setup() {},
};
</script>
