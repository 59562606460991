<template>
  <OptionCategory />
</template>
<script>
import OptionCategory from "@/components/partials/general/OptionCategory.vue";
export default {
  components: {
    OptionCategory,
  },
  setup() {},
};
</script>
