<template>
  <div class="wrapper">
    <div class="container">
      <div class="divider">
        <div class="header-title">
          <h2>Payment Confirmation</h2>
        </div>
        <div class="content">
          <div class="col-data">
            <span class="description">Plan Code</span>
            <span class="value">{{ $store.state.planDetails.PlanCode }}</span>
          </div>
          <div class="col-data">
            <span class="description">Premium</span>
            <span class="value">{{
              pesoFormat($store.state.formData.premium)
            }}</span>
          </div>
          <div class="col-data">
            <span class="description">Coverage</span>
            <span class="value">1 Year</span>
          </div>
          <div class="col-seperator">
            <hr />
          </div>
          <div class="col-data-total">
            <span class="description">
              <h3>Total Amount:</h3>
            </span>
            <span class="value"
              ><h3>
                {{ pesoFormat(totalPaidAmount) }}
              </h3></span
            >
          </div>
        </div>
        <div class="payment-method">
          <div class="selection">
            <span> <h4>Select Payment Method</h4></span>
            <ul>
              <li
                v-for="(item, index) in paymentItems"
                :key="index"
                :class="{ active: item.description === paymentMethod }"
                @click="selectedHandler((paymentMethod = item.description))"
              >
                <input
                  type="radio"
                  name="payment"
                  :value="item.description"
                  v-model="paymentMethod"
                />

                <label for="">{{ item.description }}</label>
                <span v-for="(item, index1) in item.icon" :key="index1">
                  <img
                    class="icons"
                    :src="require(`@/assets/icons/${item}`)"
                    alt="icon"
                  />
                </span>
              </li>
            </ul>
          </div>
          <div class="col-seperator">
            <div class="important">
              <span class="note">
                <h5>Please read</h5>
                <p>
                  This program gives the member a
                  <b>15-days “free look” </b>period during which, he/she may
                  review the program's provisions and if no benefit has been
                  availed of, the member may opt to have his/her membership
                  cancelled and have the amount paid via Gcash or credit card
                  refunded.
                </p>
              </span>
            </div>
            <div class="confirmation" v-if="paymentMethod !== 'Cash & Card'">
              <span
                >For GCash or credit card payment, input the amount to be paid.
                Click PROCEED TO PAYMENT.</span
              >
              <input
                :class="{
                  error: !isMatch && confirmAmount,
                }"
                type="number"
                v-model="confirmAmount"
                @input="confirmHandler()"
              />
            </div>
          </div>
          <div class="btn-checkout loading-container">
            <app-loading v-if="loading" />
            <input
              v-if="loading"
              :disabled="loading"
              type="button"
              :value="!loading ? 'Proceed to Payment' : null"
              @click.once="nextHandler()"
            />
            <input
              v-else
              type="button"
              :value="!loading ? 'Proceed to Payment' : null"
              @click="nextHandler()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import validationAlert from "@/utils/reusables/SwalAlert";
import ProcessData from "@/data/ProcessData";
import { computed, inject, ref } from "vue";
export default {
  components: {},
  setup() {
    const { createdClients, loading, countdown } = ProcessData();
    const { paymentConfirmation } = validationAlert();
    const store = inject("$store");
    const paymentMethod = ref(null);
    const selectedTransaction = ref(null);
    const confirmAmount = ref(0);
    const isMatch = ref(true);
    const isWaiting = ref(false);
    const cardAmount = ref(0);
    const cashAmount = ref(0);
    const isError = ref(false);
    const paymentMethodItems = ref([
      {
        id: 1,
        description: "Cash",
        isActive: true,
        icon: ["cash.png"],
      },
      {
        id: 2,
        description: "GCASH via QR Code",
        isActive: false,
        icon: ["gcash.png"],
      },
      {
        id: 3,
        description: "Debit/Credit Card (via POS)",
        isActive: true,
        icon: ["visa.png", "mastercard.png", "amex.png"],
      },
      {
        id: 4,
        description: "Cheque",
        isActive: false,
        icon: ["cheque.png"],
      },
      {
        id: 5,
        description: "Credit Card (via DragonPay/Paymaya)",
        isActive: false,
        icon: ["gcash.png"],
      },
    ]);

    const paymentItems = computed(() => {
      return paymentMethodItems.value.filter((val) => {
        return val.isActive;
      });
    });
    const pesoFormat = (val) => {
      let modified = val.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });
      return `${modified}`;
    };
    const confirmHandler = () => {
      if (confirmAmount.value === 0) {
        isMatch.value = true;
      } else if (confirmAmount.value === totalPaidAmount.value) {
        isMatch.value = true;
      } else {
        isMatch.value = false;
      }
    };
    const selectedHandler = () => {
      isMatch.value = true;
      cardAmount.value = 0;
      cashAmount.value = 0;
      confirmAmount.value = 0;
      selectedTransaction.value = null;
    };
    const nextHandler = () => {
      if (isMatch.value && paymentMethod.value) {
        isWaiting.value = true;
        loading.value = true;
        createdClients(paymentMethod.value, confirmAmount.value);
      } else {
        paymentConfirmation();
      }
    };
    const totalPaidAmount = computed(() => {
      return store.state.formData.premium;
    });
    return {
      confirmAmount,
      paymentMethod,
      paymentMethodItems,
      isMatch,
      isWaiting,
      cashAmount,
      cardAmount,
      loading,
      countdown,
      pesoFormat,
      confirmHandler,
      selectedHandler,
      nextHandler,
      createdClients,
      paymentConfirmation,
      selectedTransaction,
      isError,
      totalPaidAmount,
      paymentItems,
    };
  },
};
</script>

<style scoped>
.divider {
  display: flex;
  flex-direction: column;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.content .col-data {
  margin: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.col-seperator {
  margin: 5px 20px;
}
.content .col-data-total {
  margin: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.content .col-data .description,
.content .col-data-total .description {
  font-weight: 500;
}
.content .col-data .value,
.content .col-data-total .value {
  font-weight: 600;
}
.col-seperator .confirmation {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.col-seperator .confirmation input {
  height: 50px;
  font-size: 18px;
  text-align: right;
  font-weight: 600;
}
.col-seperator .important {
  padding: 0px 0px 18px 0px;
}
.col-seperator .important .note h5 {
  color: var(--error-color);
  font-size: 14px;
  padding-bottom: 5px;
}
.col-seperator .important .note p {
  font-size: 14px;
  line-height: 18px;
}
.payment-method {
  margin: 20px;
}
.payment-method .selection ul {
  list-style-type: none;
  margin: 8px 15px;
}
.payment-method .selection ul > li {
  min-height: 50px;
  padding: 0px 10px;
  margin: 5px 0px;
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid rgb(76, 75, 77);
  border-radius: 10px;
}
.payment-method .selection ul > li:hover {
  background-color: var(--hover-color);
  border: 1px solid var(--primary-color);
  color: var(--light-color);
}
.active {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--light-color);
}
.payment-method .btn-checkout input {
  margin: 20px 0px 30px 0px;
  background-color: var(--secondary-color);
  width: 90px;
  color: var(--light-color);
  padding: 20px 10px;
  height: 60px;
  border: none;
  border-radius: 20px;
  min-width: 100%;
}
select {
  padding: 10px;
  margin: 0px 15px;
  min-width: 294px;
}
.content .col-data-discount {
  margin: 15px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.content .col-data-subtotal {
  margin: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.content .col-data-subtotal .description,
.content .col-data-subtotal .value {
  font-size: 14px;
  font-weight: 100;
}
.label-message {
  font-size: 14px;
  margin-top: -10px;
  padding: 0px 20px;
  color: var(--error-color);
}
</style>
