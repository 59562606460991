<template>
  <GreetingMessage />
</template>
<script>
import GreetingMessage from "@/components/partials/general/application/GreetingMessage.vue";
export default {
  components: {
    GreetingMessage,
  },
  setup() {},
};
</script>
