<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon">
    <path
      d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"
    ></path>
  </svg>
</template>
<style scoped>
.icon {
  fill: var(--accent-color);
  width: 25px !important;
  height: 25px !important;
}
</style>
