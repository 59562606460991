<template>
  <div class="wrapper">
    <div class="container">
      <div class="divider">
        <div class="header-icon">
          <greetingSVG id="svg" />
          <div class="header-message">
            <h2>You're almost done!</h2>
            <p style="text-indent: 8%">
              To complete your application,Input POS receipt or
              transaction/service invoice number corresponding to your receipt.
              Then click submitted, you will shortly receive an email
              notification.
            </p>
          </div>
          <form @submit.prevent="submitHandler()">
            <div class="input-confirmation-code">
              <div class="tooltip-container">
                <label for="reference-number">Reference Number</label>
                <input
                  class="card-palette"
                  id="reference-number"
                  :type="isShowConfirmPassword ? 'text' : 'password'"
                  v-model="confirmationCode"
                  @input="checkIfValid()"
                  required
                />
                <span
                  v-if="isHaveError"
                  class="password-icon"
                  @click="isShowConfirmPassword = !isShowConfirmPassword"
                >
                  <showPasswordSVG class="icon" v-if="isShowConfirmPassword" />
                  <hidePasswordSVG class="icon" v-else />
                </span>
                <span class="tooltip-text" v-if="isValidationOne">{{
                  isValidationOneMessage
                }}</span>
              </div>
            </div>
            <div class="input-confirmation-code">
              <div class="tooltip-container">
                <label for="retype-reference-number"
                  >Re-type Reference Number</label
                >
                <input
                  class="card-palette"
                  id="retype-reference-number"
                  :type="isShowConfirmPassword ? 'text' : 'password'"
                  v-model="ReconfirmationCode"
                  @input="checkMatchHandler()"
                  required
                />
                <span
                  class="password-icon"
                  @click="isShowConfirmPassword = !isShowConfirmPassword"
                >
                  <showPasswordSVG class="icon" v-if="isShowConfirmPassword" />
                  <hidePasswordSVG class="icon" v-else />
                </span>
                <span class="tooltip-text" v-if="isValidationTwo">{{
                  isValidationTwoMessage
                }}</span>
              </div>
            </div>
            <div class="btn-confirmation-code loading-container">
              <app-loading v-if="isLoading" />
              <input
                type="submit"
                :value="isLoading ? '' : 'Submit'"
                :disabled="isDisabled"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adminData from "@/data/ProcessData";
import greetingSVG from "@/components/svgs/greetingSVG.vue";
import hidePasswordSVG from "@/components/svgs/hidePasswordSVG.vue";
import showPasswordSVG from "@/components/svgs/showPasswordSVG.vue";
import swalAlert from "@/utils/reusables/SwalAlert";
import { useRoute } from "vue-router";
import { ref, inject } from "vue";
export default {
  components: {
    greetingSVG,
    hidePasswordSVG,
    showPasswordSVG,
  },
  setup() {
    const { updateTransaction, isLoading } = adminData();
    const { conifrmationReferenceHandler } = swalAlert();
    const route = useRoute();
    const store = inject("$store");
    const transactionID = route.params.id;
    const confirmationCode = ref(null);
    const ReconfirmationCode = ref(null);
    const isDisabled = ref(false);
    const ifMatched = ref(false);
    const isValidationOne = ref(false);
    const isValidationOneMessage = ref(null);
    const isValidationTwo = ref(false);
    const isValidationTwoMessage = ref(null);
    const isShowConfirmPassword = ref(false);
    const tempKeyPassword = ref([]);
    const isHaveError = ref(false);
    const checkIfValid = () => {
      if (!confirmationCode.value.length) {
        isValidationOne.value = false;
      } else if (confirmationCode.value.length === 12) {
        isValidationOne.value = false;
        tempKeyPassword.value.push(confirmationCode.value);
        console.log(tempKeyPassword.value);
      } else if (confirmationCode.value.length > 12) {
        isValidationOneMessage.value =
          "Please input reference code with 12 characters.";
        isValidationOne.value = true;
      } else {
        isValidationOneMessage.value =
          "Reference code must be exactly 12 characters.";
        isValidationOne.value = true;
      }
    };
    const checkMatchHandler = () => {
      // Clear validation initially
      isValidationTwo.value = false;

      // If the re-typed reference number is empty, clear match state
      if (!ReconfirmationCode.value.length) {
        ifMatched.value = false;
        return;
      }

      // Iterate digit by digit to compare with the stored confirmation code
      const reconfirmValue = ReconfirmationCode.value;

      // Ensure both inputs have the same length first

      if (reconfirmValue.length > tempKeyPassword.value[0].length) {
        console.log("Render");
        isValidationTwoMessage.value =
          "The retyped code exceeds the original code length.";
        isValidationTwo.value = true;
        ifMatched.value = false;
        return;
      }

      // Compare each digit one by one
      for (let i = 0; i < reconfirmValue.length; i++) {
        if (reconfirmValue[i] !== tempKeyPassword.value[0][i]) {
          isHaveError.value = true;
          ifMatched.value = false;
          isValidationTwoMessage.value = `The retyped code does not match at digit ${
            i + 1
          }.`;
          isValidationTwo.value = true;

          return;
        } else {
          isHaveError.value = false;
        }
      }

      // If all digits match and lengths are equal
      if (reconfirmValue === tempKeyPassword.value[0]) {
        ifMatched.value = true;
        isValidationTwo.value = false; // No validation error
      }
    };
    const submitHandler = () => {
      if (!isValidationOne.value && ifMatched.value) {
        isDisabled.value = true;
        isLoading.value = true;
        if (isDisabled.value) {
          updateTransaction(transactionID, confirmationCode.value);
          //updateTransaction(transactionID, confirmationCode.value);
          store.commit("resetState");
        } else {
          isDisabled.value = false;
        }
      } else {
        conifrmationReferenceHandler();
      }
    };

    return {
      updateTransaction,
      submitHandler,
      confirmationCode,
      ReconfirmationCode,
      transactionID,
      isDisabled,
      isLoading,
      ifMatched,
      isValidationOne,
      isValidationOneMessage,
      isValidationTwo,
      isValidationTwoMessage,
      isShowConfirmPassword,
      tempKeyPassword,
      isHaveError,
      checkIfValid,
      checkMatchHandler,
      conifrmationReferenceHandler,
    };
  },
};
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: column;
}
.header-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.header-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px;
}
.header-message h2 {
  padding: 10px 0px;
}
.header-message p {
  line-height: 24px;
}

.input-confirmation-code {
  width: 100%;
  margin-bottom: 20px;
}

label {
  font-size: 14px;
  color: var(--dark-color);
  margin: 42px 0px;
}

.input-confirmation-code .tooltip-container input {
  height: 50px;
  font-size: 18px;
  width: 100%;
  color: #464444;
  padding: 0 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  top: -45px; /* Position above the input */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  font-size: 14px;
  z-index: 1;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%; /* Arrow at the bottom */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.btn-confirmation-code input {
  height: 50px;
  width: 100%;
  font-size: 18px;
  text-align: center;
  background-color: var(--secondary-color);

  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-confirmation-code input:hover {
  background-color: var(--hover-color);
}

.btn-confirmation-code input:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.tooltip-container {
  position: relative; /* Ensure the icon is positioned relative to the input container */
  display: inline-block;
  width: 100%;
}

.password-icon {
  position: absolute;
  right: 10px; /* Adjust as necessary for padding on the right */
  top: 62%;
  transform: translateY(-50%); /* Center the icon vertically */
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon {
  height: 25px; /* Adjust size if necessary */
  width: 25px;
  color: var(--semi-light-color); /* Optional: customize icon color */
}
#svg {
  margin: 30px 0;
  height: 140px;
  width: 140px;
}
form {
  margin: 0px 28px;
}
</style>
