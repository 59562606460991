<template>
  <keep-alive>
    <component :is="currentPage" @actionPage="fetchActionPage"></component>
  </keep-alive>
  <div class="footer">
    <span class="version">{{ appVersion }}</span>
  </div>
</template>
<script>
import AgentProfile from "@/components/partials/general/application/AgentProfile.vue";
import PlanType from "@/components/partials/general/application/PlantType.vue";
import PlanDetails from "@/components/partials/general/application/PlanDetails.vue";
import ClientInformation from "@/components/partials/general/application/ClientInformation.vue";
import TermCondition from "@/components/partials/general/application/TermCondition.vue";
import PaymentConfirmation from "@/components/partials/general/application/PaymentConfirmation.vue";
import GreetingMessage from "@/components/partials/general/application/GreetingMessage.vue";
import { ref, computed } from "vue";
import systemVersion from "@/utils/reusables/SystemVersion";
export default {
  components: {
    AgentProfile,
    PlanType,
    PlanDetails,
    ClientInformation,
    TermCondition,
    PaymentConfirmation,
    GreetingMessage,
  },
  setup() {
    const selectedPage = ref("AgentProfile");
    const planDetails = ref([]);
    const currentPage = computed(() => {
      return selectedPage.value;
    });
    const { appVersion } = systemVersion();
    const fetchActionPage = (action, payLoad) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      selectedPage.value = action;
      planDetails.value = payLoad;
    };

    return {
      selectedPage,
      currentPage,
      planDetails,
      appVersion,
      fetchActionPage,
    };
  },
};
</script>
<style scoped>
.footer {
  margin-bottom: 20px;
}
.footer .version {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
</style>
